import { StorageKeys } from 'constant/localStorage'
import {
  IGetConfigHelpResponse,
  IHelpCategories,
  IHelpResponse,
  IInitialConfigResponse,
  ILanguageHelpOption,
  ILanguageOption,
  IGetUserReportsConfigQueryResponse,
  IUserReportsConfigResponse,
  IRetrieveTokenParams,
  IRetrieveTokenResponse,
  IFuseResponse,
} from 'interfaces'
import { setLocalStorage } from 'utils/localStorage'

import { setAppInitialConfig, setInitialFetchFailure, setFuse } from 'store/slices'
import { evAPI } from './api'

export const appApi = evAPI.injectEndpoints({
  endpoints: builder => ({
    getInitialConfig: builder.query<IInitialConfigResponse, void>({
      query: () => 'App/GetInitialConfigQuery',
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const payload = (await queryFulfilled).data.data
          dispatch(setAppInitialConfig(payload))
        } catch {
          dispatch(setInitialFetchFailure())
        }
      },
      providesTags: ['AppConfig'],
    }),

    getHelp: builder.query<IHelpCategories, string>({
      query: languageUUID => `App/getHelpQuery?languageUUID=${languageUUID}`,
      transformResponse: (response: IHelpResponse) => response.data,
      providesTags: ['Help'],
      keepUnusedDataFor: 0,
    }),
    getHelpConfig: builder.query<ILanguageOption[], void>({
      query: () => `App/getHelpConfigQuery`,
      transformResponse: (response: IGetConfigHelpResponse) => {
        const { languages } = response.data
        return languages.reduce((acc: ILanguageOption[], curr: ILanguageHelpOption) => {
          return [
            ...acc,
            { label: curr.name, value: curr.languageUUID, isoCode: curr.isoCode },
          ] as ILanguageOption[]
        }, [])
      },
    }),
    getUserReportsConfig: builder.query<IUserReportsConfigResponse, void>({
      query: () => `Reports/GetUserReportsConfigQuery`,
      keepUnusedDataFor: 30 * 60,
      transformResponse: (response: IGetUserReportsConfigQueryResponse) => response.data,
      providesTags: ['ReportsConfig'],
    }),
    validateToken: builder.query<void, void>({
      // returns 200 if valid, and returns 401 if token is invalid - which is then handled by the fetchbase
      query: () => 'App/ValidateToken',
    }),
    retrieveBearerToken: builder.mutation<IRetrieveTokenResponse, IRetrieveTokenParams>({
      query(args) {
        return {
          url: 'Users/RetrieveBearerTokenCommand',
          method: 'POST',
          body: args,
        }
      },
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        const payload = (await queryFulfilled).data
        const {
          data: { bearerToken, antiCSRFToken },
        } = payload
        if (bearerToken !== null) {
          // clear any stale data that may be in the redux store
          dispatch(evAPI.util.resetApiState())

          // set local storage with relevant tokens
          setLocalStorage(StorageKeys.token, bearerToken)
          setLocalStorage(StorageKeys.antiCSRFToken, antiCSRFToken)
        }
      },
    }),
    getFuseURL: builder.query<IFuseResponse, void>({
      query: () => 'Users/GetFuseURLQuery',
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const payload = (await queryFulfilled).data.data
        dispatch(setFuse(payload))
      },
    }),
  }),
})

export const {
  useGetInitialConfigQuery,
  useGetHelpQuery,
  useGetHelpConfigQuery,
  useGetUserReportsConfigQuery,
  useValidateTokenQuery,
  useRetrieveBearerTokenMutation,
  useGetFuseURLQuery,
} = appApi
