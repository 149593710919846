// Options for the application main navigation

import {
  List,
  ListItemButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@mui/material'
import dashboardIcon from 'assets/svgs/icon_dashboard.svg'
import evTraingingIcon from 'assets/svgs/icon_ev_training.svg'
import exploreEvIcon from 'assets/svgs/icon_explore_ev_menu.svg'
import helpIcon from 'assets/svgs/icon_help.svg'
import homeIcon from 'assets/svgs/icon_home.svg'
import evHomeIcon from 'assets/svgs/icon_ev_home.svg'
import logoutIcon from 'assets/svgs/icon_logout.svg'
import myStaffIcon from 'assets/svgs/icon_my_staff.svg'
import settingsIcon from 'assets/svgs/icon_settings.svg'
import excellenceIcon from 'assets/svgs/icon_excellence.svg'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'store'
import { selectUser, selectFuse } from 'store/slices'
import useOttRedirect from 'utils/useOttRedirect'
interface ApplicationMenuItemsProps {
  handleMenuClose?: () => void
}

const ApplicationMenuItems = ({ handleMenuClose }: ApplicationMenuItemsProps) => {
  const user = useAppSelector(selectUser)
  const fuseData = useAppSelector(selectFuse)

  const navigate = useNavigate()

  const [generatePortalRedirect, { isLoading, isSuccess }] = useOttRedirect({
    url: '/Account/LoginLMS',
    environment: 'portalLocation',
  })

  const handleNavigationItem = (to: string) => {
    navigate(to)
    handleMenuClose && handleMenuClose()
  }

  return (
    <List style={{ cursor: 'pointer' }}>
      {user?.showFuse ? (
        <ListItemButton
          key="appNavigation.menuItem.homepage"
          onClick={() => window.open(fuseData?.fuseURL, '_self')}
          disabled={user?.isImpersonate}
        >
          <ListItemIcon>
            <img src={homeIcon} alt="Home" />
          </ListItemIcon>
          <ListItemText
            primary={<FormattedMessage id="appNavigation.menuItem.homepage" />}
          />
        </ListItemButton>
      ) : null}

      <ListItem
        key="appNavigation.menuItem.home"
        onClick={() => handleNavigationItem('/')}
      >
        <ListItemIcon>
          <img src={evHomeIcon} alt="Home" />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="appNavigation.menuItem.home" />} />
      </ListItem>

      {!user?.hasEvTrainingJobRole ? (
        <ListItem
          key="app.labels.exploreEV"
          onClick={() => handleNavigationItem('/ev-level')}
        >
          <ListItemIcon>
            <img src={exploreEvIcon} alt="Explore EV" width={25} height={25} />
          </ListItemIcon>
          <ListItemText primary={<FormattedMessage id="app.labels.exploreEV" />} />
        </ListItem>
      ) : null}

      {user?.hasEvTrainingJobRole ? (
        <ListItem
          key="app.labels.myEVTraining"
          onClick={() => handleNavigationItem(`ev-level/${user?.workingLevelUUID}`)}
        >
          <ListItemIcon>
            <img src={evTraingingIcon} alt="My EV Training" />
          </ListItemIcon>
          <ListItemText primary={<FormattedMessage id="app.labels.myEVTraining" />} />
        </ListItem>
      ) : null}

      <ListItemButton
        key="appNavigation.menuItem.excellence"
        onClick={() => generatePortalRedirect()}
        disabled={user?.isImpersonate}
      >
        <ListItemIcon>
          {isLoading || isSuccess ? (
            <CircularProgress size={25} />
          ) : (
            <img src={excellenceIcon} alt="Excellence" />
          )}
        </ListItemIcon>
        <ListItemText
          primary={<FormattedMessage id="appNavigation.menuItem.excellence" />}
        />
      </ListItemButton>

      {user?.isManager || user?.isRetailerAdmin ? (
        <>
          <ListItem
            key="app.labels.dashboard"
            onClick={() => handleNavigationItem('/dashboard')}
          >
            <ListItemIcon>
              <img src={dashboardIcon} alt="Dashboard" />
            </ListItemIcon>
            <ListItemText primary={<FormattedMessage id="app.labels.dashboard" />} />
          </ListItem>
        </>
      ) : null}

      {user?.isManager || user?.isRetailerAdmin ? (
        <ListItem
          key="app.labels.myStaff"
          onClick={() => handleNavigationItem('/staff-members')}
        >
          <ListItemIcon>
            <img src={myStaffIcon} alt="My Staff" />
          </ListItemIcon>
          <ListItemText primary={<FormattedMessage id="app.labels.myStaff" />} />
        </ListItem>
      ) : null}

      {user?.isManager || user?.isRetailerAdmin ? (
        <ListItem
          key="appNavigation.menuItem.settings"
          onClick={() => {
            handleNavigationItem(`/settings`)
          }}
        >
          <ListItemIcon>
            <img src={settingsIcon} alt="Settings" />
          </ListItemIcon>
          <ListItemText
            primary={<FormattedMessage id="appNavigation.menuItem.settings" />}
          />
        </ListItem>
      ) : null}

      <ListItem
        key="app.buttons.help"
        onClick={() => handleNavigationItem('/help-center')}
      >
        <ListItemIcon>
          <img src={helpIcon} alt="Help" />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="app.buttons.help" />} />
      </ListItem>

      <ListItem
        key="appNavigation.menuItem.logout"
        onClick={() => handleNavigationItem('/logout')}
      >
        <ListItemIcon>
          <img src={logoutIcon} alt="Logout" />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="appNavigation.menuItem.logout" />} />
      </ListItem>
    </List>
  )
}

export default ApplicationMenuItems
