// Gobal application header used throughout the application

import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Stack,
  Container,
  Divider,
  Popover,
} from '@mui/material'
import jlrLogo from 'assets/svgs/JLR_blue.svg'
import { useAppSelector } from 'store'
import React, { useState } from 'react'
import ApplicationMenuItems from 'components/layout/ApplicationMenuItems'
import helpIcon from 'assets/svgs/icon_help.svg'
import menuIcon from 'assets/svgs/icon_menu.svg'
import { FormattedMessage } from 'react-intl'
import { selectUsername } from 'store/slices'
import Alert from '@mui/material/Alert'
import { selectUserIsImpersonate } from 'store/slices'

import { useNavigate } from 'react-router-dom'

import { getLocalStorage } from 'utils/localStorage'
import { StorageKeys } from 'constant/localStorage'
interface Props {
  isLargeScreen: boolean
}

export default function ApplicationHeader({ isLargeScreen }: Props) {
  const isImpersonate = useAppSelector(selectUserIsImpersonate)

  const [menuIsOpen, setMenuIsOpen] = useState<HTMLButtonElement | null>(null)
  const username =
    useAppSelector(selectUsername) ?? getLocalStorage(StorageKeys.user)?.username

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) =>
    setMenuIsOpen(e.currentTarget)
  const handleMenuClose = () => setMenuIsOpen(null)

  const navigate = useNavigate()

  const handleHelpClick = () => {
    navigate('/help-center')
  }

  return (
    <>
      <AppBar>
        <Container>
          <Toolbar
            sx={{
              py: isLargeScreen ? 3 : 2,
              px: 0,
              justifyContent: isLargeScreen ? 'inherit' : 'space-between',
              gap: isLargeScreen ? 'inherit' : '25px',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={5}
              flex={1}
              divider={
                <Divider
                  orientation="vertical"
                  sx={{ bgcolor: '#1E1E1E', height: '35px' }}
                />
              }
            >
              <img
                src={jlrLogo}
                alt="Jaguar LandRover"
                width={isLargeScreen ? 40 : 44}
                data-testid="logo"
                onClick={() => navigate('/')}
                style={{ cursor: 'pointer' }}
              />
              {isLargeScreen ? (
                <Typography
                  sx={{ fontWeight: 100 }}
                  variant="button"
                  color="inherit"
                  fontSize={24}
                >
                  <FormattedMessage id="application.title" />
                </Typography>
              ) : null}
            </Stack>
            {isLargeScreen ? (
              <IconButton sx={{ mr: 2 }} onClick={handleHelpClick}>
                <img src={helpIcon} alt="Help" data-testid="HelpIcon" />
              </IconButton>
            ) : null}
            {username ? (
              <Typography
                color="inherit"
                variant="overline"
                fontSize={16}
                fontWeight={100}
                data-testid="username"
                lineHeight="unset"
              >
                {username}
              </Typography>
            ) : null}
            <IconButton
              data-testid="app-navigation-button"
              sx={{ ml: 2 }}
              edge="end"
              onClick={handleMenuClick}
            >
              <img
                src={menuIcon}
                alt="Menu"
                width={22}
                height={22}
                data-testid="MenuIcon"
              />
            </IconButton>
          </Toolbar>
        </Container>
        {isImpersonate && (
          <Alert
            style={{
              justifyContent: 'center',
              borderTop: '1px solid #DDD',
              borderRadius: '0px',
            }}
            severity="error"
          >
            <FormattedMessage id="errorCodes.NO_ACTION_WHILE_IMPERSONATING" />
          </Alert>
        )}
      </AppBar>
      <Popover
        open={Boolean(menuIsOpen)}
        anchorEl={menuIsOpen}
        onClose={handleMenuClose}
        data-testid="app-navigation"
        sx={{ mt: 1 }}
        PaperProps={{ sx: { minWidth: 200 } }}
      >
        <ApplicationMenuItems handleMenuClose={handleMenuClose} />
      </Popover>
    </>
  )
}
