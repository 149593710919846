import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFuseState } from 'interfaces'
import { RootState } from 'store'

const initialState: IFuseState = {
  fuseHasErrors: false,
  fuseURL: '',
}

const fuseSlice = createSlice({
  name: 'fuseSlice',
  initialState: initialState,
  reducers: {
    setFuse(state, action: PayloadAction<IFuseState>) {
      state.fuseHasErrors = action.payload.fuseHasErrors
      state.fuseURL = action.payload.fuseURL
    },
  },
})

export const { setFuse } = fuseSlice.actions

export const selectFuse = (state: RootState) => state.fuse

export default fuseSlice.reducer
